<template>
  <div class="header">
    <div class="header-labels mb-3 mr-3">
      <template v-for="(button, key) in labelButtons">
        <div class="btn-wrapper" :class="button.class" v-bind:key="key">
          <a
            :class="['header-labels__btn helptext', button.class]"
            v-show="button.show"
            v-bind:key="key"
            v-bind:style="{ ...button.styles, padding: button.buttonPadding }"
            v-bind:variant="button.variant"
            v-bind:disabled="button.disabled"
            v-bind:href="button.link"
            v-on:click="checkIfFunc($event, button.action)"
          >
            <template v-if="button.icon">
              <font-awesome-icon
                :class="{ no_label: !button.label }"
                v-bind:icon="button.icon"
              />
            </template>

            <span>{{ button.label }}</span>

            <div class="helptext-content" v-if="button.titleAttribute">
              {{ button.titleAttribute }}
            </div>
          </a>
        </div>
      </template>
    </div>

    <div class="header-buttons">
      <slot name="header-actions"></slot>
      <template v-for="(button, key) in separateButtons">
        <!-- v-bind:action="button.action" 
					button.action && typeof button.action === 'function' ? button.action($event) : () => {} -->
        <Button
          :class="['header-buttons__btn mb-3 mr-3 helptext', button.class]"
          v-show="button.show"
          v-bind:key="key"
          v-bind:styles="button.styles"
          v-bind:variant="button.variant"
          v-bind:disabled="button.disabled"
          v-bind:label="button.label"
          v-bind:icon="button.icon"
          v-bind:color="button.color"
          v-bind:bg-color="button.bgColor"
          v-bind:icon-position="button.iconPosition"
          v-bind:filter="button.filter"
          v-bind:opacity="button.opacity"
          v-on:click="checkIfFunc($event, button)"
        >
          <!-- <template v-if="button.icon" >
						<font-awesome-icon v-bind:icon="button.icon"/>
					</template>

					<div class="helptext-content" v-if="button.titleAttribute">{{button.titleAttribute}}</div> -->
        </Button>
      </template>
    </div>

    <div class="header-actions mb-3">
      <template v-for="(button, key) in buttonsList">
        <div class="btn-wrapper" :class="button.class" v-bind:key="key">
          <Button
            :class="['header-actions__btn helptext', button.class]"
            v-bind:key="key"
            v-bind:styles="{ ...button.styles, padding: button.buttonPadding }"
            v-bind:variant="button.variant"
            v-bind:disabled="button.disabled"
            v-bind:label="button.label"
            v-bind:icon="button.icon"
            v-on:click="checkIfFunc($event, button)"
          >
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import { mapMutations } from "vuex";

export default {
  name: "HeaderLabel",
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    entityLabel: {
      type: [String, Number],
      default: "",
    },
    childEntityLabel: {
      type: String,
      default: "",
    },
    labelButtons: {
      type: Array,
      default: () => [],
    },
    buttonsList: {
      type: Array,
      default: () => [],
    },
    separateButtons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapMutations("helper", {
      setBreadcrumbs: "setBreadcrumbs",
    }),
    checkIfFunc(event, button) {
      return button.action && typeof button.action === "function"
        ? button.action(event)
        : () => {};
    },
    vueTourEvent(button) {
      if (button.kind && button.kind === "general-settings") {
        this.$root.$emit("click-general-settings-btn");
      }
    },
  },
  mounted() {
    if (this.childEntityLabel) {
      this.setBreadcrumbs({
        prefix: this.title,
        title: `${this.entityLabel} - ${this.childEntityLabel}`,
      });
    } else {
      this.setBreadcrumbs({ prefix: this.title, title: this.entityLabel });
    }
  },
  watch: {
    title() {
      this.setBreadcrumbs({ prefix: this.title, title: this.entityLabel });
    },
    entityLabel() {
      if (this.childEntityLabel) {
        this.setBreadcrumbs({
          prefix: this.title,
          title: `${this.entityLabel} - ${this.childEntityLabel}`,
        });
      } else {
        this.setBreadcrumbs({ prefix: this.title, title: this.entityLabel });
      }
    },
    childEntityLabel() {
      if (this.childEntityLabel) {
        this.setBreadcrumbs({
          prefix: this.title,
          title: `${this.entityLabel} - ${this.childEntityLabel}`,
        });
      } else {
        this.setBreadcrumbs({ prefix: this.title, title: this.entityLabel });
      }
    },
  },
  beforeDestroy() {
    this.setBreadcrumbs({});
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/multi-component/public";
@import "@/styles/multi-component/tooltip";
@import "@/styles/variables";

.header {
  width: 100%;
  position: relative;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 1px solid $light_gray;
  margin-bottom: 1.9rem;
  margin-top: 15px;

  @media (min-width: 992px) {
    grid-template-columns: auto auto 1fr;
    margin-top: 15px;
  }

  .helptext-content {
    font-weight: 400;
    font-size: 0.875rem;
  }

  .header-labels {
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #ededed;
    border-radius: 15px;

    @media (min-width: 1200px) {
      border-radius: 20px;
    }

    @media (min-width: 1400px) {
      border-radius: 25px;
    }

    // .btn-wrapper {

    // &:not(:last-child) {
    // margin-right: 5px;
    // }
    // }

    &__btn {
      border-radius: 15px;
      position: relative;
      color: $gray_text_2;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 0.875rem;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease;
      line-height: 1.2em;
      letter-spacing: 1px;
      padding: 12px 20px;

      @media (min-width: 1200px) {
        border-radius: 20px;
      }

      @media (min-width: 1400px) {
        border-radius: 25px;
      }

      &.current,
      &:hover {
        color: #fff;
        text-decoration: none;
        background: rgb(108, 65, 226);
      }

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        left: 15px;
        fill: #6c757d;
      }

      @media (min-width: 1200px) {
        border-radius: 20px;
      }

      @media (min-width: 1400px) {
        border-radius: 25px;
      }
    }
  }

  .header-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__btn {
      border-radius: 15px;
      position: relative;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.2em;
      padding: 12px 20px;

      &.btn-outline-warning {
        background-color: #fff0db;
        color: #fcbd01;
        border: unset;
        transition: all 0.3s ease;

        &:hover {
          background-color: #ffebd2;
        }
      }

      &.btn-outline-danger {
        padding-right: 43px;
        background-color: #fedfe5;
        color: $danger;
        border: unset;
        transition: all 0.3s ease;

        &:hover {
          background-color: #fed4de;
        }
      }

      svg {
        color: $danger;
        background-color: $white;
        width: 20px;
        height: 20px;
        padding: 2px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
      }

      @media (min-width: 1200px) {
        border-radius: 20px;
      }

      @media (min-width: 1400px) {
        border-radius: 25px;
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;
    //background: #dbe5e8;
    border-radius: 15px;

    @media (min-width: 992px) {
      justify-self: end;
    }

    a {
      font-size: 1rem;
      span {
        font-size: 0.6rem;
      }
    }

    @media (min-width: 1200px) {
      border-radius: 20px;
    }

    @media (min-width: 1400px) {
      border-radius: 25px;
    }

    .btn-wrapper {
      border-radius: 15px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    &__btn {
      color: $light_text;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.3s ease;
      line-height: 1.2em;
      padding: 12px 20px;
      letter-spacing: 1px;
      position: relative;
      border-radius: 15px;

      @media (min-width: 1200px) {
        border-radius: 20px;
      }

      @media (min-width: 1400px) {
        border-radius: 25px;
      }

      &:hover {
        text-decoration: none;
        background: $white;
      }

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        left: 15px;
        fill: #6c757d;
        transition: all 0.3s ease;

        &.no_label {
          position: static;
          transform: none;
          left: unset;
          top: unset;
        }
      }

      &.visibility {
        @media (min-width: 1400px) {
          display: none;
        }
      }

      &.current {
        background: $white;
      }
    }
  }
}
</style>
